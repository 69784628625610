'use strict';

class Easing {
	constructor(el){
		this.el = el;
	}

	render(){
		this.onScroll();
	}

	onScroll(){
		let
			isShow = false,
			showPos,
			show = ()=>{
				if( isShow ) return;
				showPos = $(this.el).offset().top - app.Util.wh()*3/4;
				if( !isShow && app.Util.sy() > showPos ){
					isShow = true;
					$(this.el).addClass('is-show');
				}
			};
			window.addEventListener('scroll', show);
			window.addEventListener('load', show);
	}
}

export default Easing;
