'use strict';

import Config from '../util/Config';
import Util from '../util/Util';
import HtmlClass from '../util/HtmlClass';
import SmoothScroll from '../util/SmoothScroll';
import Module from '../components/Module';

class App {
	constructor(){
		this.html = document.querySelector('html');
		this.Config = Config;
		this.Util = new Util();
		this.Module = new Module();
	}

	render(){
		HtmlClass.render();
		this.Module.render();		
	}
}

export default App;
