'use strict';

import Easing from '../components/Easing';

class Module{
  constructor(){}

  render(){
    let fade = document.querySelectorAll('.fade');
    _.each(fade, (el)=>{
  		 el.fade = new Easing(el);
       el.fade.render();
  	});
  }
}
export default Module;
